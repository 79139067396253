enifed('ember-runtime/mixins/copyable', ['exports', 'ember-metal', 'ember-debug', 'ember-runtime/mixins/freezable'], function (exports, _emberMetal, _emberDebug, _freezable) {
  'use strict';

  exports.default = _emberMetal.Mixin.create({
    /**
      __Required.__ You must implement this method to apply this mixin.
       Override to return a copy of the receiver. Default implementation raises
      an exception.
       @method copy
      @param {Boolean} deep if `true`, a deep copy of the object should be made
      @return {Object} copy of receiver
      @private
    */
    copy: null,

    /**
      If the object implements `Ember.Freezable`, then this will return a new
      copy if the object is not frozen and the receiver if the object is frozen.
       Raises an exception if you try to call this method on a object that does
      not support freezing.
       You should use this method whenever you want a copy of a freezable object
      since a freezable object can simply return itself without actually
      consuming more memory.
       @method frozenCopy
      @return {Object} copy of receiver or receiver
      @deprecated Use `Object.freeze` instead.
      @private
    */
    frozenCopy: function () {
      (true && !(false) && (0, _emberDebug.deprecate)('`frozenCopy` is deprecated, use `Object.freeze` instead.', false, { id: 'ember-runtime.frozen-copy', until: '3.0.0' }));

      if (_freezable.Freezable && _freezable.Freezable.detect(this)) {
        return (0, _emberMetal.get)(this, 'isFrozen') ? this : this.copy().freeze();
      } else {
        throw new _emberDebug.Error(this + ' does not support freezing');
      }
    }
  });
});