enifed('ember-routing/system/cache', ['exports', 'ember-runtime'], function (exports, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    init: function () {
      this.cache = Object.create(null);
    },
    has: function (bucketKey) {
      return !!this.cache[bucketKey];
    },
    stash: function (bucketKey, key, value) {
      var bucket = this.cache[bucketKey];

      if (!bucket) {
        bucket = this.cache[bucketKey] = Object.create(null);
      }

      bucket[key] = value;
    },
    lookup: function (bucketKey, prop, defaultValue) {
      var cache = this.cache;
      if (!this.has(bucketKey)) {
        return defaultValue;
      }

      var bucket = cache[bucketKey];
      if (prop in bucket && bucket[prop] !== undefined) {
        return bucket[prop];
      } else {
        return defaultValue;
      }
    }
  });
});