enifed('ember-template-compiler/plugins/extract-pragma-tag', ['exports'], function (exports) {
  'use strict';

  exports.default = extractPragmaTag;
  var PRAGMA_TAG = 'use-component-manager';

  function extractPragmaTag(env) {
    var meta = env.meta;


    return {
      name: 'exract-pragma-tag',

      visitors: {
        MustacheStatement: {
          enter: function (node) {
            if (node.path.type === 'PathExpression' && node.path.original === PRAGMA_TAG) {
              meta.managerId = node.params[0].value;
              return null;
            }
          }
        }
      }
    };
  }
});