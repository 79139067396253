enifed('ember-routing/system/router_state', ['exports', 'ember-utils', 'ember-routing/utils', 'ember-runtime'], function (exports, _emberUtils, _utils, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    emberRouter: null,
    routerJs: null,
    routerJsState: null,

    isActiveIntent: function (routeName, models, queryParams, queryParamsMustMatch) {
      var state = this.routerJsState;
      if (!this.routerJs.isActiveIntent(routeName, models, null, state)) {
        return false;
      }

      if (queryParamsMustMatch && Object.keys(queryParams).length > 0) {
        var visibleQueryParams = (0, _emberUtils.assign)({}, queryParams);

        this.emberRouter._prepareQueryParams(routeName, models, visibleQueryParams);
        return (0, _utils.shallowEqual)(visibleQueryParams, state.queryParams);
      }

      return true;
    }
  });
});