enifed("ember-glimmer/syntax/utils", ["exports"], function (exports) {
  "use strict";

  exports.hashToArgs = hashToArgs;
  function hashToArgs(hash) {
    if (hash === null) return null;
    var names = hash[0].map(function (key) {
      return "@" + key;
    });
    return [names, hash[1]];
  }
});