enifed('ember-glimmer/dom', ['exports', '@glimmer/runtime', '@glimmer/node'], function (exports, _runtime, _node) {
  'use strict';

  Object.defineProperty(exports, 'DOMChanges', {
    enumerable: true,
    get: function () {
      return _runtime.DOMChanges;
    }
  });
  Object.defineProperty(exports, 'DOMTreeConstruction', {
    enumerable: true,
    get: function () {
      return _runtime.DOMTreeConstruction;
    }
  });
  Object.defineProperty(exports, 'NodeDOMTreeConstruction', {
    enumerable: true,
    get: function () {
      return _node.NodeDOMTreeConstruction;
    }
  });
});