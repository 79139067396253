enifed('ember-runtime/mixins/promise_proxy', ['exports', 'ember-metal', 'ember-debug', 'ember-runtime/computed/computed_macros'], function (exports, _emberMetal, _emberDebug, _computed_macros) {
  'use strict';

  /**
    @module @ember/object
  */

  function tap(proxy, promise) {
    (0, _emberMetal.setProperties)(proxy, {
      isFulfilled: false,
      isRejected: false
    });

    return promise.then(function (value) {
      if (!proxy.isDestroyed && !proxy.isDestroying) {
        (0, _emberMetal.setProperties)(proxy, {
          content: value,
          isFulfilled: true
        });
      }
      return value;
    }, function (reason) {
      if (!proxy.isDestroyed && !proxy.isDestroying) {
        (0, _emberMetal.setProperties)(proxy, {
          reason: reason,
          isRejected: true
        });
      }
      throw reason;
    }, 'Ember: PromiseProxy');
  }

  /**
    A low level mixin making ObjectProxy promise-aware.
  
    ```javascript
    let ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  
    let proxy = ObjectPromiseProxy.create({
      promise: Ember.RSVP.resolve($.getJSON('/some/remote/data.json'))
    });
  
    proxy.then(function(json){
       // the json
    }, function(reason) {
       // the reason why you have no json
    });
    ```
  
    the proxy has bindable attributes which
    track the promises life cycle
  
    ```javascript
    proxy.get('isPending')   //=> true
    proxy.get('isSettled')  //=> false
    proxy.get('isRejected')  //=> false
    proxy.get('isFulfilled') //=> false
    ```
  
    When the $.getJSON completes, and the promise is fulfilled
    with json, the life cycle attributes will update accordingly.
    Note that $.getJSON doesn't return an ECMA specified promise,
    it is useful to wrap this with an `RSVP.resolve` so that it behaves
    as a spec compliant promise.
  
    ```javascript
    proxy.get('isPending')   //=> false
    proxy.get('isSettled')   //=> true
    proxy.get('isRejected')  //=> false
    proxy.get('isFulfilled') //=> true
    ```
  
    As the proxy is an ObjectProxy, and the json now its content,
    all the json properties will be available directly from the proxy.
  
    ```javascript
    // Assuming the following json:
    {
      firstName: 'Stefan',
      lastName: 'Penner'
    }
  
    // both properties will accessible on the proxy
    proxy.get('firstName') //=> 'Stefan'
    proxy.get('lastName')  //=> 'Penner'
    ```
  
    @class PromiseProxyMixin
    @public
  */
  exports.default = _emberMetal.Mixin.create({
    /**
      If the proxied promise is rejected this will contain the reason
      provided.
       @property reason
      @default null
      @public
    */
    reason: null,

    /**
      Once the proxied promise has settled this will become `false`.
       @property isPending
      @default true
      @public
    */
    isPending: (0, _computed_macros.not)('isSettled').readOnly(),

    /**
      Once the proxied promise has settled this will become `true`.
       @property isSettled
      @default false
      @public
    */
    isSettled: (0, _computed_macros.or)('isRejected', 'isFulfilled').readOnly(),

    /**
      Will become `true` if the proxied promise is rejected.
       @property isRejected
      @default false
      @public
    */
    isRejected: false,

    /**
      Will become `true` if the proxied promise is fulfilled.
       @property isFulfilled
      @default false
      @public
    */
    isFulfilled: false,

    /**
      The promise whose fulfillment value is being proxied by this object.
       This property must be specified upon creation, and should not be
      changed once created.
       Example:
       ```javascript
      Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
        promise: <thenable>
      });
      ```
       @property promise
      @public
    */
    promise: (0, _emberMetal.computed)({
      get: function () {
        throw new _emberDebug.Error('PromiseProxy\'s promise must be set');
      },
      set: function (key, promise) {
        return tap(this, promise);
      }
    }),

    /**
      An alias to the proxied promise's `then`.
       See RSVP.Promise.then.
       @method then
      @param {Function} callback
      @return {RSVP.Promise}
      @public
    */
    then: promiseAlias('then'),

    /**
      An alias to the proxied promise's `catch`.
       See RSVP.Promise.catch.
       @method catch
      @param {Function} callback
      @return {RSVP.Promise}
      @since 1.3.0
      @public
    */
    'catch': promiseAlias('catch'),

    /**
      An alias to the proxied promise's `finally`.
       See RSVP.Promise.finally.
       @method finally
      @param {Function} callback
      @return {RSVP.Promise}
      @since 1.3.0
      @public
    */
    'finally': promiseAlias('finally')

  });


  function promiseAlias(name) {
    return function () {
      var promise = (0, _emberMetal.get)(this, 'promise');
      return promise[name].apply(promise, arguments);
    };
  }
});