enifed('ember-template-compiler/index', ['exports', 'ember-template-compiler/system/precompile', 'ember-template-compiler/system/compile', 'ember-template-compiler/system/compile-options', 'ember-template-compiler/plugins', 'ember-metal', 'ember/features', 'ember-environment', 'ember/version', 'ember-template-compiler/compat', 'ember-template-compiler/system/bootstrap'], function (exports, _precompile, _compile, _compileOptions, _plugins, _emberMetal, _features, _emberEnvironment, _version) {
  'use strict';

  exports.defaultPlugins = exports.registerPlugin = exports.compileOptions = exports.compile = exports.precompile = exports._Ember = undefined;
  Object.defineProperty(exports, 'precompile', {
    enumerable: true,
    get: function () {
      return _precompile.default;
    }
  });
  Object.defineProperty(exports, 'compile', {
    enumerable: true,
    get: function () {
      return _compile.default;
    }
  });
  Object.defineProperty(exports, 'compileOptions', {
    enumerable: true,
    get: function () {
      return _compileOptions.default;
    }
  });
  Object.defineProperty(exports, 'registerPlugin', {
    enumerable: true,
    get: function () {
      return _compileOptions.registerPlugin;
    }
  });
  Object.defineProperty(exports, 'defaultPlugins', {
    enumerable: true,
    get: function () {
      return _plugins.default;
    }
  });


  // private API used by ember-cli-htmlbars to setup ENV and FEATURES
  if (!_emberMetal.default.ENV) {
    _emberMetal.default.ENV = _emberEnvironment.ENV;
  }
  if (!_emberMetal.default.FEATURES) {
    _emberMetal.default.FEATURES = _features.FEATURES;
  }
  if (!_emberMetal.default.VERSION) {
    _emberMetal.default.VERSION = _version.default;
  }

  exports._Ember = _emberMetal.default;
});