enifed('ember-glimmer/helpers/readonly', ['exports', 'ember-glimmer/utils/references', 'ember-glimmer/helpers/mut'], function (exports, _references, _mut) {
  'use strict';

  exports.default = function (vm, args) {
    var ref = (0, _mut.unMut)(args.positional.at(0));

    var wrapped = Object.create(ref);

    wrapped[_references.UPDATE] = undefined;

    return wrapped;
  };
});