enifed('ember-runtime/mixins/container_proxy', ['exports', 'ember-metal'], function (exports, _emberMetal) {
  'use strict';

  /**
    ContainerProxyMixin is used to provide public access to specific
    container functionality.
  
    @class ContainerProxyMixin
    @private
  */
  var containerProxyMixin = {
    /**
     The container stores state.
      @private
     @property {Ember.Container} __container__
     */
    __container__: null,

    ownerInjection: function () {
      return this.__container__.ownerInjection();
    },
    lookup: function (fullName, options) {
      return this.__container__.lookup(fullName, options);
    },
    _resolveLocalLookupName: function (name, source) {
      return this.__container__.registry.expandLocalLookup('component:' + name, {
        source: source
      });
    },
    willDestroy: function () {
      this._super.apply(this, arguments);

      if (this.__container__) {
        (0, _emberMetal.run)(this.__container__, 'destroy');
      }
    },
    factoryFor: function (fullName) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.__container__.factoryFor(fullName, options);
    }
  }; /**
     @module ember
     */
  exports.default = _emberMetal.Mixin.create(containerProxyMixin);
});