enifed('ember-glimmer/utils/debug-stack', ['exports', 'ember-babel'], function (exports, _emberBabel) {
  'use strict';

  var DebugStack = void 0;

  if (true) {
    var Element = function Element(name) {
      (0, _emberBabel.classCallCheck)(this, Element);

      this.name = name;
    };

    var TemplateElement = function (_Element) {
      (0, _emberBabel.inherits)(TemplateElement, _Element);

      function TemplateElement() {
        (0, _emberBabel.classCallCheck)(this, TemplateElement);
        return (0, _emberBabel.possibleConstructorReturn)(this, _Element.apply(this, arguments));
      }

      return TemplateElement;
    }(Element);

    var EngineElement = function (_Element2) {
      (0, _emberBabel.inherits)(EngineElement, _Element2);

      function EngineElement() {
        (0, _emberBabel.classCallCheck)(this, EngineElement);
        return (0, _emberBabel.possibleConstructorReturn)(this, _Element2.apply(this, arguments));
      }

      return EngineElement;
    }(Element);

    DebugStack = function () {
      function DebugStack() {
        (0, _emberBabel.classCallCheck)(this, DebugStack);

        this._stack = [];
      }

      DebugStack.prototype.push = function push(name) {
        this._stack.push(new TemplateElement(name));
      };

      DebugStack.prototype.pushEngine = function pushEngine(name) {
        this._stack.push(new EngineElement(name));
      };

      DebugStack.prototype.pop = function pop() {
        var element = this._stack.pop();

        if (element) {
          return element.name;
        }
      };

      DebugStack.prototype.peek = function peek() {
        var template = this._currentTemplate();
        var engine = this._currentEngine();

        if (engine) {
          return '"' + template + '" (in "' + engine + '")';
        } else if (template) {
          return '"' + template + '"';
        }
      };

      DebugStack.prototype._currentTemplate = function _currentTemplate() {
        return this._getCurrentByType(TemplateElement);
      };

      DebugStack.prototype._currentEngine = function _currentEngine() {
        return this._getCurrentByType(EngineElement);
      };

      DebugStack.prototype._getCurrentByType = function _getCurrentByType(type) {
        for (var i = this._stack.length; i >= 0; i--) {
          var element = this._stack[i];
          if (element instanceof type) {
            return element.name;
          }
        }
      };

      return DebugStack;
    }();
  }

  exports.default = DebugStack;
});