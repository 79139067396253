enifed('ember-testing/test/run', ['exports', 'ember-metal'], function (exports, _emberMetal) {
  'use strict';

  exports.default = run;
  function run(fn) {
    if (!_emberMetal.run.currentRunLoop) {
      return (0, _emberMetal.run)(fn);
    } else {
      return fn();
    }
  }
});