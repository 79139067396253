enifed("ember-runtime/string_registry", ["exports"], function (exports) {
  "use strict";

  exports.setStrings = setStrings;
  exports.getStrings = getStrings;
  exports.get = get;
  // STATE within a module is frowned upon, this exists
  // to support Ember.STRINGS but shield ember internals from this legacy global
  // API.
  var STRINGS = {};

  function setStrings(strings) {
    STRINGS = strings;
  }

  function getStrings() {
    return STRINGS;
  }

  function get(name) {
    return STRINGS[name];
  }
});