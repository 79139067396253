enifed('ember-glimmer/components/checkbox', ['exports', 'ember-metal', 'ember-glimmer/component', 'ember-glimmer/templates/empty'], function (exports, _emberMetal, _component, _empty) {
  'use strict';

  exports.default = _component.default.extend({
    layout: _empty.default,
    classNames: ['ember-checkbox'],

    tagName: 'input',

    attributeBindings: ['type', 'checked', 'indeterminate', 'disabled', 'tabindex', 'name', 'autofocus', 'required', 'form'],

    type: 'checkbox',
    disabled: false,
    indeterminate: false,

    didInsertElement: function () {
      this._super.apply(this, arguments);
      (0, _emberMetal.get)(this, 'element').indeterminate = !!(0, _emberMetal.get)(this, 'indeterminate');
    },
    change: function () {
      (0, _emberMetal.set)(this, 'checked', this.$().prop('checked'));
    }
  });
});