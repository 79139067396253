enifed('ember-glimmer/component-managers/abstract', ['exports', 'ember-babel'], function (exports, _emberBabel) {
  'use strict';

  var AbstractManager = function () {
    function AbstractManager() {
      (0, _emberBabel.classCallCheck)(this, AbstractManager);

      this.debugStack = undefined;
    }

    AbstractManager.prototype.prepareArgs = function prepareArgs(definition, args) {
      return null;
    };

    AbstractManager.prototype.create = function create(env, definition, args, dynamicScope, caller, hasBlock) {
      if (true) {
        throw new Error('AbstractManager#create must be implemented.');
      }
    };

    AbstractManager.prototype.layoutFor = function layoutFor(definition, bucket, env) {
      if (true) {
        throw new Error('AbstractManager#create must be implemented.');
      }
    };

    AbstractManager.prototype.getSelf = function getSelf(bucket) {
      return bucket;
    };

    AbstractManager.prototype.didCreateElement = function didCreateElement(bucket, element, operations) {};

    AbstractManager.prototype.didRenderLayout = function didRenderLayout(bucket, bounds) {};

    AbstractManager.prototype.didCreate = function didCreate(bucket) {};

    AbstractManager.prototype.getTag = function getTag(bucket) {
      return null;
    };

    AbstractManager.prototype.update = function update(bucket, dynamicScope) {};

    AbstractManager.prototype.didUpdateLayout = function didUpdateLayout(bucket, bounds) {};

    AbstractManager.prototype.didUpdate = function didUpdate(bucket) {};

    AbstractManager.prototype.getDestructor = function getDestructor(bucket) {};

    return AbstractManager;
  }();

  exports.default = AbstractManager;


  if (true) {
    AbstractManager.prototype._pushToDebugStack = function (name, environment) {
      this.debugStack = environment.debugStack;
      this.debugStack.push(name);
    };

    AbstractManager.prototype._pushEngineToDebugStack = function (name, environment) {
      this.debugStack = environment.debugStack;
      this.debugStack.pushEngine(name);
    };
  }
});