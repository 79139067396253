enifed('ember-glimmer/helpers/get', ['exports', 'ember-babel', 'ember-metal', 'ember-glimmer/utils/references', '@glimmer/reference'], function (exports, _emberBabel, _emberMetal, _references, _reference) {
  'use strict';

  exports.default = function (vm, args) {
    return GetHelperReference.create(args.positional.at(0), args.positional.at(1));
  };

  var GetHelperReference = function (_CachedReference) {
    (0, _emberBabel.inherits)(GetHelperReference, _CachedReference);

    GetHelperReference.create = function create(sourceReference, pathReference) {
      if ((0, _reference.isConst)(pathReference)) {
        var parts = pathReference.value().split('.');
        return (0, _reference.referenceFromParts)(sourceReference, parts);
      } else {
        return new GetHelperReference(sourceReference, pathReference);
      }
    };

    function GetHelperReference(sourceReference, pathReference) {
      (0, _emberBabel.classCallCheck)(this, GetHelperReference);

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.call(this));

      _this.sourceReference = sourceReference;
      _this.pathReference = pathReference;

      _this.lastPath = null;
      _this.innerReference = null;

      var innerTag = _this.innerTag = new _reference.UpdatableTag(_reference.CONSTANT_TAG);

      _this.tag = (0, _reference.combine)([sourceReference.tag, pathReference.tag, innerTag]);
      return _this;
    }

    GetHelperReference.prototype.compute = function compute() {
      var lastPath = this.lastPath,
          innerReference = this.innerReference,
          innerTag = this.innerTag;


      var path = this.lastPath = this.pathReference.value();

      if (path !== lastPath) {
        if (path !== undefined && path !== null && path !== '') {
          var pathType = typeof path;

          if (pathType === 'string') {
            innerReference = this.innerReference = (0, _reference.referenceFromParts)(this.sourceReference, path.split('.'));
          } else if (pathType === 'number') {
            innerReference = this.innerReference = this.sourceReference.get('' + path);
          }

          innerTag.update(innerReference.tag);
        } else {
          innerReference = this.innerReference = null;
          innerTag.update(_reference.CONSTANT_TAG);
        }
      }

      return innerReference ? innerReference.value() : null;
    };

    GetHelperReference.prototype[_references.UPDATE] = function (value) {
      (0, _emberMetal.set)(this.sourceReference.value(), this.pathReference.value(), value);
    };

    return GetHelperReference;
  }(_references.CachedReference);
});