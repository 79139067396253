enifed('ember-glimmer/syntax/dynamic-component', ['exports', 'ember-babel', '@glimmer/runtime', '@glimmer/reference', 'ember-debug', 'ember-glimmer/syntax/utils'], function (exports, _emberBabel, _runtime, _reference, _emberDebug, _utils) {
  'use strict';

  exports.dynamicComponentMacro = dynamicComponentMacro;
  exports.blockComponentMacro = blockComponentMacro;
  exports.inlineComponentMacro = inlineComponentMacro;


  function dynamicComponentFor(vm, args, meta) {
    var env = vm.env;
    var nameRef = args.positional.at(0);

    return new DynamicComponentReference({ nameRef: nameRef, env: env, meta: meta });
  }

  function dynamicComponentMacro(params, hash, _default, inverse, builder) {
    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), (0, _utils.hashToArgs)(hash), null, null];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args);
    return true;
  }

  function blockComponentMacro(params, hash, _default, inverse, builder) {
    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), (0, _utils.hashToArgs)(hash), _default, inverse];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args);
    return true;
  }

  function inlineComponentMacro(name, params, hash, builder) {
    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), (0, _utils.hashToArgs)(hash), null, null];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args);
    return true;
  }

  var DynamicComponentReference = function () {
    function DynamicComponentReference(_ref) {
      var nameRef = _ref.nameRef,
          env = _ref.env,
          meta = _ref.meta,
          args = _ref.args;
      (0, _emberBabel.classCallCheck)(this, DynamicComponentReference);

      this.tag = nameRef.tag;
      this.nameRef = nameRef;
      this.env = env;
      this.meta = meta;
      this.args = args;
    }

    DynamicComponentReference.prototype.value = function value() {
      var env = this.env,
          nameRef = this.nameRef,
          meta = this.meta;

      var nameOrDef = nameRef.value();

      if (typeof nameOrDef === 'string') {
        var definition = env.getComponentDefinition(nameOrDef, meta);

        (true && !(definition) && (0, _emberDebug.assert)('Could not find component named "' + nameOrDef + '" (no component or template with that name was found)', definition));


        return definition;
      } else if ((0, _runtime.isComponentDefinition)(nameOrDef)) {
        return nameOrDef;
      } else {
        return null;
      }
    };

    DynamicComponentReference.prototype.get = function get() {
      return _reference.UNDEFINED_REFERENCE;
    };

    return DynamicComponentReference;
  }();
});