enifed('container', ['exports', 'ember-babel', 'ember-utils', 'ember-debug', 'ember/features'], function (exports, _emberBabel, _emberUtils, _emberDebug, _features) {
  'use strict';

  exports.Container = exports.privatize = exports.Registry = undefined;


  /* globals Proxy */
  var CONTAINER_OVERRIDE = (0, _emberUtils.symbol)('CONTAINER_OVERRIDE');

  /**
   A container used to instantiate and cache objects.
  
   Every `Container` must be associated with a `Registry`, which is referenced
   to determine the factory and options that should be used to instantiate
   objects.
  
   The public API for `Container` is still in flux and should not be considered
   stable.
  
   @private
   @class Container
   */
  function Container(registry) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    this.registry = registry;
    this.owner = options.owner || null;
    this.cache = (0, _emberUtils.dictionary)(options.cache || null);
    this.factoryManagerCache = (0, _emberUtils.dictionary)(options.factoryManagerCache || null);
    this[CONTAINER_OVERRIDE] = undefined;
    this.isDestroyed = false;

    if (true) {
      this.validationCache = (0, _emberUtils.dictionary)(options.validationCache || null);
    }
  }

  Container.prototype = {
    lookup: function (fullName, options) {
      (true && !(this.registry.validateFullName(fullName)) && (0, _emberDebug.assert)('fullName must be a proper full name', this.registry.validateFullName(fullName)));

      return lookup(this, this.registry.normalize(fullName), options);
    },
    destroy: function () {
      destroyDestroyables(this);
      this.isDestroyed = true;
    },
    reset: function (fullName) {
      if (fullName !== undefined) {
        resetMember(this, this.registry.normalize(fullName));
      } else {
        resetCache(this);
      }
    },
    ownerInjection: function () {
      var _ref;

      return _ref = {}, _ref[_emberUtils.OWNER] = this.owner, _ref;
    },
    _resolverCacheKey: function (name, options) {
      return this.registry.resolverCacheKey(name, options);
    },
    factoryFor: function (fullName) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var normalizedName = this.registry.normalize(fullName);

      (true && !(this.registry.validateFullName(normalizedName)) && (0, _emberDebug.assert)('fullName must be a proper full name', this.registry.validateFullName(normalizedName)));


      if (options.source) {
        var expandedFullName = this.registry.expandLocalLookup(fullName, options);
        // if expandLocalLookup returns falsey, we do not support local lookup
        if (!_features.EMBER_MODULE_UNIFICATION) {
          if (!expandedFullName) {
            return;
          }

          normalizedName = expandedFullName;
        } else if (expandedFullName) {
          // with ember-module-unification, if expandLocalLookup returns something,
          // pass it to the resolve without the source
          normalizedName = expandedFullName;
          options = {};
        }
      }

      var cacheKey = this._resolverCacheKey(normalizedName, options);
      var cached = this.factoryManagerCache[cacheKey];

      if (cached !== undefined) {
        return cached;
      }

      var factory = _features.EMBER_MODULE_UNIFICATION ? this.registry.resolve(normalizedName, options) : this.registry.resolve(normalizedName);

      if (factory === undefined) {
        return;
      }

      if (true && factory && typeof factory._onLookup === 'function') {
        factory._onLookup(fullName);
      }

      var manager = new FactoryManager(this, factory, fullName, normalizedName);

      if (true) {
        manager = wrapManagerInDeprecationProxy(manager);
      }

      this.factoryManagerCache[cacheKey] = manager;
      return manager;
    }
  };

  /*
   * Wrap a factory manager in a proxy which will not permit properties to be
   * set on the manager.
   */
  function wrapManagerInDeprecationProxy(manager) {
    if (_emberUtils.HAS_NATIVE_PROXY) {
      var validator = {
        set: function (obj, prop, value) {
          throw new Error('You attempted to set "' + prop + '" on a factory manager created by container#factoryFor. A factory manager is a read-only construct.');
        }
      };

      // Note:
      // We have to proxy access to the manager here so that private property
      // access doesn't cause the above errors to occur.
      var m = manager;
      var proxiedManager = {
        class: m.class,
        create: function (props) {
          return m.create(props);
        }
      };

      return new Proxy(proxiedManager, validator);
    }

    return manager;
  }

  function isSingleton(container, fullName) {
    return container.registry.getOption(fullName, 'singleton') !== false;
  }

  function isInstantiatable(container, fullName) {
    return container.registry.getOption(fullName, 'instantiate') !== false;
  }

  function lookup(container, fullName) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (options.source) {
      var expandedFullName = container.registry.expandLocalLookup(fullName, options);

      if (!_features.EMBER_MODULE_UNIFICATION) {
        // if expandLocalLookup returns falsey, we do not support local lookup
        if (!expandedFullName) {
          return;
        }

        fullName = expandedFullName;
      } else if (expandedFullName) {
        // with ember-module-unification, if expandLocalLookup returns something,
        // pass it to the resolve without the source
        fullName = expandedFullName;
        options = {};
      }
    }

    var cacheKey = container._resolverCacheKey(fullName, options);
    var cached = container.cache[cacheKey];
    if (cached !== undefined && options.singleton !== false) {
      return cached;
    }

    return instantiateFactory(container, fullName, options);
  }

  function isSingletonClass(container, fullName, _ref2) {
    var instantiate = _ref2.instantiate,
        singleton = _ref2.singleton;

    return singleton !== false && !instantiate && isSingleton(container, fullName) && !isInstantiatable(container, fullName);
  }

  function isSingletonInstance(container, fullName, _ref3) {
    var instantiate = _ref3.instantiate,
        singleton = _ref3.singleton;

    return singleton !== false && instantiate !== false && isSingleton(container, fullName) && isInstantiatable(container, fullName);
  }

  function isFactoryClass(container, fullname, _ref4) {
    var instantiate = _ref4.instantiate,
        singleton = _ref4.singleton;

    return instantiate === false && (singleton === false || !isSingleton(container, fullname)) && !isInstantiatable(container, fullname);
  }

  function isFactoryInstance(container, fullName, _ref5) {
    var instantiate = _ref5.instantiate,
        singleton = _ref5.singleton;

    return instantiate !== false && (singleton !== false || isSingleton(container, fullName)) && isInstantiatable(container, fullName);
  }

  function instantiateFactory(container, fullName, options) {
    var factoryManager = _features.EMBER_MODULE_UNIFICATION && options && options.source ? container.factoryFor(fullName, options) : container.factoryFor(fullName);

    if (factoryManager === undefined) {
      return;
    }

    var cacheKey = container._resolverCacheKey(fullName, options);

    // SomeClass { singleton: true, instantiate: true } | { singleton: true } | { instantiate: true } | {}
    // By default majority of objects fall into this case
    if (isSingletonInstance(container, fullName, options)) {
      return container.cache[cacheKey] = factoryManager.create();
    }

    // SomeClass { singleton: false, instantiate: true }
    if (isFactoryInstance(container, fullName, options)) {
      return factoryManager.create();
    }

    // SomeClass { singleton: true, instantiate: false } | { instantiate: false } | { singleton: false, instantiation: false }
    if (isSingletonClass(container, fullName, options) || isFactoryClass(container, fullName, options)) {
      return factoryManager.class;
    }

    throw new Error('Could not create factory');
  }

  function markInjectionsAsDynamic(injections) {
    injections._dynamic = true;
  }

  function areInjectionsNotDynamic(injections) {
    return injections._dynamic !== true;
  }

  function buildInjections() /* container, ...injections */{
    var hash = {};

    if (arguments.length > 1) {
      var container = arguments[0];
      var injections = [];
      var injection = void 0;

      for (var i = 1; i < arguments.length; i++) {
        if (arguments[i]) {
          injections = injections.concat(arguments[i]);
        }
      }

      if (true) {
        container.registry.validateInjections(injections);
      }

      var markAsDynamic = false;
      for (var _i = 0; _i < injections.length; _i++) {
        injection = injections[_i];
        hash[injection.property] = lookup(container, injection.fullName);
        if (!markAsDynamic) {
          markAsDynamic = !isSingleton(container, injection.fullName);
        }
      }

      if (markAsDynamic) {
        markInjectionsAsDynamic(hash);
      }
    }

    return hash;
  }

  function injectionsFor(container, fullName) {
    var registry = container.registry;
    var splitName = fullName.split(':');
    var type = splitName[0];

    var injections = buildInjections(container, registry.getTypeInjections(type), registry.getInjections(fullName));

    return injections;
  }

  function destroyDestroyables(container) {
    var cache = container.cache;
    var keys = Object.keys(cache);

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var value = cache[key];

      if (isInstantiatable(container, key) && value.destroy) {
        value.destroy();
      }
    }
  }

  function resetCache(container) {
    destroyDestroyables(container);
    container.cache.dict = (0, _emberUtils.dictionary)(null);
  }

  function resetMember(container, fullName) {
    var member = container.cache[fullName];

    delete container.factoryManagerCache[fullName];

    if (member) {
      delete container.cache[fullName];

      if (member.destroy) {
        member.destroy();
      }
    }
  }

  var FactoryManager = function () {
    function FactoryManager(container, factory, fullName, normalizedName) {
      (0, _emberBabel.classCallCheck)(this, FactoryManager);

      this.container = container;
      this.owner = container.owner;
      this.class = factory;
      this.fullName = fullName;
      this.normalizedName = normalizedName;
      this.madeToString = undefined;
      this.injections = undefined;
    }

    FactoryManager.prototype.toString = function toString() {
      if (!this.madeToString) {
        this.madeToString = this.container.registry.makeToString(this.class, this.fullName);
      }

      return this.madeToString;
    };

    FactoryManager.prototype.create = function create() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var injections = this.injections;
      if (injections === undefined) {
        injections = injectionsFor(this.container, this.normalizedName);
        if (areInjectionsNotDynamic(injections)) {
          this.injections = injections;
        }
      }
      var props = (0, _emberUtils.assign)({}, injections, options);

      if (true) {
        var lazyInjections = void 0;
        var validationCache = this.container.validationCache;
        // Ensure that all lazy injections are valid at instantiation time
        if (!validationCache[this.fullName] && this.class && typeof this.class._lazyInjections === 'function') {
          lazyInjections = this.class._lazyInjections();
          lazyInjections = this.container.registry.normalizeInjectionsHash(lazyInjections);

          this.container.registry.validateInjections(lazyInjections);
        }

        validationCache[this.fullName] = true;
      }

      if (!this.class.create) {
        throw new Error('Failed to create an instance of \'' + this.normalizedName + '\'. Most likely an improperly defined class or' + ' an invalid module export.');
      }

      // required to allow access to things like
      // the customized toString, _debugContainerKey,
      // owner, etc. without a double extend and without
      // modifying the objects properties
      if (typeof this.class._initFactory === 'function') {
        this.class._initFactory(this);
      } else {
        // in the non-EmberObject case we need to still setOwner
        // this is required for supporting glimmer environment and
        // template instantiation which rely heavily on
        // `options[OWNER]` being passed into `create`
        // TODO: clean this up, and remove in future versions
        (0, _emberUtils.setOwner)(props, this.owner);
      }

      return this.class.create(props);
    };

    return FactoryManager;
  }();

  var VALID_FULL_NAME_REGEXP = /^[^:]+:[^:]+$/;

  /**
   A registry used to store factory and option information keyed
   by type.
  
   A `Registry` stores the factory and option information needed by a
   `Container` to instantiate and cache objects.
  
   The API for `Registry` is still in flux and should not be considered stable.
  
   @private
   @class Registry
   @since 1.11.0
  */
  function Registry() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    this.fallback = options.fallback || null;

    if (options.resolver) {
      this.resolver = options.resolver;
      if (typeof this.resolver === 'function') {
        deprecateResolverFunction(this);
      }
    }

    this.registrations = (0, _emberUtils.dictionary)(options.registrations || null);

    this._typeInjections = (0, _emberUtils.dictionary)(null);
    this._injections = (0, _emberUtils.dictionary)(null);

    this._localLookupCache = Object.create(null);
    this._normalizeCache = (0, _emberUtils.dictionary)(null);
    this._resolveCache = (0, _emberUtils.dictionary)(null);
    this._failCache = (0, _emberUtils.dictionary)(null);

    this._options = (0, _emberUtils.dictionary)(null);
    this._typeOptions = (0, _emberUtils.dictionary)(null);
  }

  Registry.prototype = {
    /**
     A backup registry for resolving registrations when no matches can be found.
      @private
     @property fallback
     @type Registry
     */
    fallback: null,

    /**
     An object that has a `resolve` method that resolves a name.
      @private
     @property resolver
     @type Resolver
     */
    resolver: null,

    /**
     @private
     @property registrations
     @type InheritingDict
     */
    registrations: null,

    /**
     @private
      @property _typeInjections
     @type InheritingDict
     */
    _typeInjections: null,

    /**
     @private
      @property _injections
     @type InheritingDict
     */
    _injections: null,

    /**
     @private
      @property _normalizeCache
     @type InheritingDict
     */
    _normalizeCache: null,

    /**
     @private
      @property _resolveCache
     @type InheritingDict
     */
    _resolveCache: null,

    /**
     @private
      @property _options
     @type InheritingDict
     */
    _options: null,

    /**
     @private
      @property _typeOptions
     @type InheritingDict
     */
    _typeOptions: null,

    container: function (options) {
      return new Container(this, options);
    },
    register: function (fullName, factory) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      (true && !(this.validateFullName(fullName)) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName)));


      if (factory === undefined) {
        throw new TypeError('Attempting to register an unknown factory: \'' + fullName + '\'');
      }

      var normalizedName = this.normalize(fullName);

      if (this._resolveCache[normalizedName]) {
        throw new Error('Cannot re-register: \'' + fullName + '\', as it has already been resolved.');
      }

      delete this._failCache[normalizedName];
      this.registrations[normalizedName] = factory;
      this._options[normalizedName] = options;
    },
    unregister: function (fullName) {
      (true && !(this.validateFullName(fullName)) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName)));


      var normalizedName = this.normalize(fullName);

      this._localLookupCache = Object.create(null);

      delete this.registrations[normalizedName];
      delete this._resolveCache[normalizedName];
      delete this._failCache[normalizedName];
      delete this._options[normalizedName];
    },
    resolve: function (fullName, options) {
      (true && !(this.validateFullName(fullName)) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName)));

      var factory = resolve(this, this.normalize(fullName), options);
      if (factory === undefined && this.fallback) {
        var _fallback;

        factory = (_fallback = this.fallback).resolve.apply(_fallback, arguments);
      }
      return factory;
    },
    describe: function (fullName) {
      if (this.resolver && this.resolver.lookupDescription) {
        return this.resolver.lookupDescription(fullName);
      } else if (this.fallback) {
        return this.fallback.describe(fullName);
      } else {
        return fullName;
      }
    },
    normalizeFullName: function (fullName) {
      if (this.resolver && this.resolver.normalize) {
        return this.resolver.normalize(fullName);
      } else if (this.fallback) {
        return this.fallback.normalizeFullName(fullName);
      } else {
        return fullName;
      }
    },
    normalize: function (fullName) {
      return this._normalizeCache[fullName] || (this._normalizeCache[fullName] = this.normalizeFullName(fullName));
    },
    makeToString: function (factory, fullName) {
      if (this.resolver && this.resolver.makeToString) {
        return this.resolver.makeToString(factory, fullName);
      } else if (this.fallback) {
        return this.fallback.makeToString(factory, fullName);
      } else {
        return factory.toString();
      }
    },
    has: function (fullName, options) {
      if (!this.isValidFullName(fullName)) {
        return false;
      }

      var source = options && options.source && this.normalize(options.source);

      return has(this, this.normalize(fullName), source);
    },
    optionsForType: function (type, options) {
      this._typeOptions[type] = options;
    },
    getOptionsForType: function (type) {
      var optionsForType = this._typeOptions[type];
      if (optionsForType === undefined && this.fallback) {
        optionsForType = this.fallback.getOptionsForType(type);
      }
      return optionsForType;
    },
    options: function (fullName) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var normalizedName = this.normalize(fullName);
      this._options[normalizedName] = options;
    },
    getOptions: function (fullName) {
      var normalizedName = this.normalize(fullName);
      var options = this._options[normalizedName];

      if (options === undefined && this.fallback) {
        options = this.fallback.getOptions(fullName);
      }
      return options;
    },
    getOption: function (fullName, optionName) {
      var options = this._options[fullName];

      if (options && options[optionName] !== undefined) {
        return options[optionName];
      }

      var type = fullName.split(':')[0];
      options = this._typeOptions[type];

      if (options && options[optionName] !== undefined) {
        return options[optionName];
      } else if (this.fallback) {
        return this.fallback.getOption(fullName, optionName);
      }
    },
    typeInjection: function (type, property, fullName) {
      (true && !(this.validateFullName(fullName)) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName)));


      var fullNameType = fullName.split(':')[0];
      if (fullNameType === type) {
        throw new Error('Cannot inject a \'' + fullName + '\' on other ' + type + '(s).');
      }

      var injections = this._typeInjections[type] || (this._typeInjections[type] = []);

      injections.push({
        property: property,
        fullName: fullName
      });
    },
    injection: function (fullName, property, injectionName) {
      this.validateFullName(injectionName);
      var normalizedInjectionName = this.normalize(injectionName);

      if (fullName.indexOf(':') === -1) {
        return this.typeInjection(fullName, property, normalizedInjectionName);
      }

      (true && !(this.validateFullName(fullName)) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName)));

      var normalizedName = this.normalize(fullName);

      var injections = this._injections[normalizedName] || (this._injections[normalizedName] = []);

      injections.push({
        property: property,
        fullName: normalizedInjectionName
      });
    },
    knownForType: function (type) {
      var fallbackKnown = void 0,
          resolverKnown = void 0;

      var localKnown = (0, _emberUtils.dictionary)(null);
      var registeredNames = Object.keys(this.registrations);
      for (var index = 0; index < registeredNames.length; index++) {
        var fullName = registeredNames[index];
        var itemType = fullName.split(':')[0];

        if (itemType === type) {
          localKnown[fullName] = true;
        }
      }

      if (this.fallback) {
        fallbackKnown = this.fallback.knownForType(type);
      }

      if (this.resolver && this.resolver.knownForType) {
        resolverKnown = this.resolver.knownForType(type);
      }

      return (0, _emberUtils.assign)({}, fallbackKnown, localKnown, resolverKnown);
    },
    validateFullName: function (fullName) {
      if (!this.isValidFullName(fullName)) {
        throw new TypeError('Invalid Fullname, expected: \'type:name\' got: ' + fullName);
      }

      return true;
    },
    isValidFullName: function (fullName) {
      return VALID_FULL_NAME_REGEXP.test(fullName);
    },
    normalizeInjectionsHash: function (hash) {
      var injections = [];

      for (var key in hash) {
        if (hash.hasOwnProperty(key)) {
          (true && !(this.validateFullName(hash[key])) && (0, _emberDebug.assert)('Expected a proper full name, given \'' + hash[key] + '\'', this.validateFullName(hash[key])));


          injections.push({
            property: key,
            fullName: hash[key]
          });
        }
      }

      return injections;
    },
    getInjections: function (fullName) {
      var injections = this._injections[fullName] || [];
      if (this.fallback) {
        injections = injections.concat(this.fallback.getInjections(fullName));
      }
      return injections;
    },
    getTypeInjections: function (type) {
      var injections = this._typeInjections[type] || [];
      if (this.fallback) {
        injections = injections.concat(this.fallback.getTypeInjections(type));
      }
      return injections;
    },
    resolverCacheKey: function (name, options) {
      if (!_features.EMBER_MODULE_UNIFICATION) {
        return name;
      }

      return options && options.source ? options.source + ':' + name : name;
    }
  };

  function deprecateResolverFunction(registry) {
    (true && !(false) && (0, _emberDebug.deprecate)('Passing a `resolver` function into a Registry is deprecated. Please pass in a Resolver object with a `resolve` method.', false, { id: 'ember-application.registry-resolver-as-function', until: '3.0.0', url: 'https://emberjs.com/deprecations/v2.x#toc_registry-resolver-as-function' }));

    registry.resolver = {
      resolve: registry.resolver
    };
  }

  if (true) {
    Registry.prototype.validateInjections = function (injections) {
      if (!injections) {
        return;
      }

      var fullName = void 0;

      for (var i = 0; i < injections.length; i++) {
        fullName = injections[i].fullName;

        (true && !(this.has(fullName)) && (0, _emberDebug.assert)('Attempting to inject an unknown injection: \'' + fullName + '\'', this.has(fullName)));
      }
    };
  }

  /**
   Given a fullName and a source fullName returns the fully resolved
   fullName. Used to allow for local lookup.
  
   ```javascript
   let registry = new Registry();
  
   // the twitter factory is added to the module system
   registry.expandLocalLookup('component:post-title', { source: 'template:post' }) // => component:post/post-title
   ```
  
   @private
   @method expandLocalLookup
   @param {String} fullName
   @param {Object} [options]
   @param {String} [options.source] the fullname of the request source (used for local lookups)
   @return {String} fullName
   */
  Registry.prototype.expandLocalLookup = function Registry_expandLocalLookup(fullName, options) {
    if (this.resolver && this.resolver.expandLocalLookup) {
      (true && !(this.validateFullName(fullName)) && (0, _emberDebug.assert)('fullName must be a proper full name', this.validateFullName(fullName)));
      (true && !(options && options.source) && (0, _emberDebug.assert)('options.source must be provided to expandLocalLookup', options && options.source));
      (true && !(this.validateFullName(options.source)) && (0, _emberDebug.assert)('options.source must be a proper full name', this.validateFullName(options.source)));


      var normalizedFullName = this.normalize(fullName);
      var normalizedSource = this.normalize(options.source);

      return expandLocalLookup(this, normalizedFullName, normalizedSource);
    } else if (this.fallback) {
      return this.fallback.expandLocalLookup(fullName, options);
    } else {
      return null;
    }
  };

  function expandLocalLookup(registry, normalizedName, normalizedSource) {
    var cache = registry._localLookupCache;
    var normalizedNameCache = cache[normalizedName];

    if (!normalizedNameCache) {
      normalizedNameCache = cache[normalizedName] = Object.create(null);
    }

    var cached = normalizedNameCache[normalizedSource];

    if (cached !== undefined) {
      return cached;
    }

    var expanded = registry.resolver.expandLocalLookup(normalizedName, normalizedSource);

    return normalizedNameCache[normalizedSource] = expanded;
  }

  function resolve(registry, normalizedName, options) {
    if (options && options.source) {
      // when `source` is provided expand normalizedName
      // and source into the full normalizedName
      var expandedNormalizedName = registry.expandLocalLookup(normalizedName, options);

      // if expandLocalLookup returns falsey, we do not support local lookup
      if (!_features.EMBER_MODULE_UNIFICATION) {
        if (!expandedNormalizedName) {
          return;
        }

        normalizedName = expandedNormalizedName;
      } else if (expandedNormalizedName) {
        // with ember-module-unification, if expandLocalLookup returns something,
        // pass it to the resolve without the source
        normalizedName = expandedNormalizedName;
        options = {};
      }
    }

    var cacheKey = registry.resolverCacheKey(normalizedName, options);
    var cached = registry._resolveCache[cacheKey];
    if (cached !== undefined) {
      return cached;
    }
    if (registry._failCache[cacheKey]) {
      return;
    }

    var resolved = void 0;

    if (registry.resolver) {
      resolved = registry.resolver.resolve(normalizedName, options && options.source);
    }

    if (resolved === undefined) {
      resolved = registry.registrations[normalizedName];
    }

    if (resolved === undefined) {
      registry._failCache[cacheKey] = true;
    } else {
      registry._resolveCache[cacheKey] = resolved;
    }

    return resolved;
  }

  function has(registry, fullName, source) {
    return registry.resolve(fullName, { source: source }) !== undefined;
  }

  var privateNames = (0, _emberUtils.dictionary)(null);
  var privateSuffix = ('' + Math.random() + Date.now()).replace('.', '');

  function privatize(_ref6) {
    var fullName = _ref6[0];

    var name = privateNames[fullName];
    if (name) {
      return name;
    }

    var _fullName$split = fullName.split(':'),
        type = _fullName$split[0],
        rawName = _fullName$split[1];

    return privateNames[fullName] = (0, _emberUtils.intern)(type + ':' + rawName + '-' + privateSuffix);
  }

  /*
  Public API for the container is still in flux.
  The public API, specified on the application namespace should be considered the stable API.
  // @module container
    @private
  */

  exports.Registry = Registry;
  exports.privatize = privatize;
  exports.Container = Container;
});