enifed('ember-routing/system/generate_controller', ['exports', 'ember-metal', 'ember-debug'], function (exports, _emberMetal, _emberDebug) {
  'use strict';

  exports.generateControllerFactory = generateControllerFactory;
  exports.default = generateController;

  /**
  @module ember
  */

  /**
    Generates a controller factory
  
    @for Ember
    @method generateControllerFactory
    @private
  */

  function generateControllerFactory(owner, controllerName, context) {
    var Factory = owner.factoryFor('controller:basic').class;

    Factory = Factory.extend({
      toString: function () {
        return '(generated ' + controllerName + ' controller)';
      }
    });

    var fullName = 'controller:' + controllerName;

    owner.register(fullName, Factory);

    return Factory;
  }

  /**
    Generates and instantiates a controller extending from `controller:basic`
    if present, or `Ember.Controller` if not.
  
    @for Ember
    @method generateController
    @private
    @since 1.3.0
  */
  function generateController(owner, controllerName) {
    generateControllerFactory(owner, controllerName);

    var fullName = 'controller:' + controllerName;
    var instance = owner.lookup(fullName);

    if (true) {
      if ((0, _emberMetal.get)(instance, 'namespace.LOG_ACTIVE_GENERATION')) {
        (0, _emberDebug.info)('generated -> ' + fullName, { fullName: fullName });
      }
    }

    return instance;
  }
});