enifed('ember-template-compiler/plugins/transform-has-block-syntax', ['exports'], function (exports) {
  'use strict';

  exports.default = transformHasBlockSyntax;
  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
   {{hasBlock}}
    ```
  
    with
  
    ```handlebars
   {{has-block}}
    ```
  
    @private
    @class TransformHasBlockSyntax
  */

  var TRANSFORMATIONS = {
    hasBlock: 'has-block',
    hasBlockParams: 'has-block-params'
  };

  function transformHasBlockSyntax(env) {
    var b = env.syntax.builders;


    return {
      name: 'transform-has-block-syntax',

      visitors: {
        PathExpression: function (node) {
          if (TRANSFORMATIONS[node.original]) {
            return b.sexpr(b.path(TRANSFORMATIONS[node.original]));
          }
        },
        MustacheStatement: function (node) {
          if (TRANSFORMATIONS[node.path.original]) {
            return b.mustache(b.path(TRANSFORMATIONS[node.path.original]), node.params, node.hash, null, node.loc);
          }
        },
        SubExpression: function (node) {
          if (TRANSFORMATIONS[node.path.original]) {
            return b.sexpr(b.path(TRANSFORMATIONS[node.path.original]), node.params, node.hash);
          }
        }
      }
    };
  }
});