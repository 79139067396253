enifed('ember-glimmer/syntax/-text-area', ['exports', 'ember-glimmer/utils/bindings', 'ember-glimmer/syntax/utils'], function (exports, _bindings, _utils) {
  'use strict';

  exports.textAreaMacro = textAreaMacro;
  function textAreaMacro(name, params, hash, builder) {
    var definition = builder.env.getComponentDefinition('-text-area', builder.meta.templateMeta);
    (0, _bindings.wrapComponentClassAttribute)(hash);
    builder.component.static(definition, [params, (0, _utils.hashToArgs)(hash), null, null]);
    return true;
  }
});