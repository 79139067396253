enifed('ember-views/mixins/view_state_support', ['exports', 'ember-metal'], function (exports, _emberMetal) {
  'use strict';

  exports.default = _emberMetal.Mixin.create({
    _transitionTo: function (state) {
      var priorState = this._currentState;
      var currentState = this._currentState = this._states[state];
      this._state = state;

      if (priorState && priorState.exit) {
        priorState.exit(this);
      }
      if (currentState.enter) {
        currentState.enter(this);
      }
    }
  });
});