enifed('ember-glimmer/helpers/loc', ['exports', 'ember-glimmer/utils/references', 'ember-runtime'], function (exports, _references, _emberRuntime) {
  'use strict';

  exports.default = function (vm, args) {
    return new _references.InternalHelperReference(locHelper, args.capture());
  };

  /**
    Calls [loc](/api/classes/Ember.String.html#method_loc) with the
    provided string. This is a convenient way to localize text within a template.
    For example:
  
    ```javascript
    Ember.STRINGS = {
      '_welcome_': 'Bonjour'
    };
    ```
  
    ```handlebars
    <div class='message'>
      {{loc '_welcome_'}}
    </div>
    ```
  
    ```html
    <div class='message'>
      Bonjour
    </div>
    ```
  
    See [Ember.String.loc](/api/classes/Ember.String.html#method_loc) for how to
    set up localized string references.
  
    @method loc
    @for Ember.Templates.helpers
    @param {String} str The string to format.
    @see {Ember.String#loc}
    @public
  */

  /**
  @module ember
  */
  function locHelper(_ref) {
    var positional = _ref.positional;

    return _emberRuntime.String.loc.apply(null, positional.value());
  }
});