enifed('ember-glimmer/utils/to-bool', ['exports', 'ember-runtime', 'ember-metal'], function (exports, _emberRuntime, _emberMetal) {
  'use strict';

  exports.default = toBool;
  function toBool(predicate) {
    if (!predicate) {
      return false;
    }

    if (predicate === true) {
      return true;
    }

    if ((0, _emberRuntime.isArray)(predicate)) {
      return (0, _emberMetal.get)(predicate, 'length') !== 0;
    }

    return true;
  }
});