enifed('ember-template-compiler/compat', ['ember-metal', 'ember-template-compiler/system/precompile', 'ember-template-compiler/system/compile', 'ember-template-compiler/system/compile-options'], function (_emberMetal, _precompile, _compile, _compileOptions) {
  'use strict';

  var EmberHandlebars = _emberMetal.default.Handlebars = _emberMetal.default.Handlebars || {}; // reexports

  var EmberHTMLBars = _emberMetal.default.HTMLBars = _emberMetal.default.HTMLBars || {};

  EmberHTMLBars.precompile = EmberHandlebars.precompile = _precompile.default;
  EmberHTMLBars.compile = EmberHandlebars.compile = _compile.default;
  EmberHTMLBars.registerPlugin = _compileOptions.registerPlugin;
});