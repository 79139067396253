enifed('ember-application/initializers/dom-templates', ['require', 'ember-glimmer', 'ember-environment', 'ember-application/system/application'], function (_require2, _emberGlimmer, _emberEnvironment, _application) {
  'use strict';

  var bootstrap = function () {};

  _application.default.initializer({
    name: 'domTemplates',
    initialize: function () {
      var bootstrapModuleId = 'ember-template-compiler/system/bootstrap';
      var context = void 0;
      if (_emberEnvironment.environment.hasDOM && (0, _require2.has)(bootstrapModuleId)) {
        bootstrap = (0, _require2.default)(bootstrapModuleId).default;
        context = document;
      }

      bootstrap({ context: context, hasTemplate: _emberGlimmer.hasTemplate, setTemplate: _emberGlimmer.setTemplate });
    }
  });
});