enifed('ember-application/system/resolver', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-runtime', 'ember-application/utils/validate-type', 'ember-glimmer'], function (exports, _emberUtils, _emberMetal, _emberDebug, _emberRuntime, _validateType, _emberGlimmer) {
  'use strict';

  exports.Resolver = undefined;
  /**
  @module @ember/application
  */

  var Resolver = exports.Resolver = _emberRuntime.Object.extend({
    /*
      This will be set to the Application instance when it is
      created.
       @property namespace
    */
    namespace: null,
    normalize: null, // required
    resolve: null, // required
    parseName: null, // required
    lookupDescription: null, // required
    makeToString: null, // required
    resolveOther: null, // required
    _logLookup: null // required
  });

  /**
    The DefaultResolver defines the default lookup rules to resolve
    container lookups before consulting the container for registered
    items:
  
    * templates are looked up on `Ember.TEMPLATES`
    * other names are looked up on the application after converting
      the name. For example, `controller:post` looks up
      `App.PostController` by default.
    * there are some nuances (see examples below)
  
    ### How Resolving Works
  
    The container calls this object's `resolve` method with the
    `fullName` argument.
  
    It first parses the fullName into an object using `parseName`.
  
    Then it checks for the presence of a type-specific instance
    method of the form `resolve[Type]` and calls it if it exists.
    For example if it was resolving 'template:post', it would call
    the `resolveTemplate` method.
  
    Its last resort is to call the `resolveOther` method.
  
    The methods of this object are designed to be easy to override
    in a subclass. For example, you could enhance how a template
    is resolved like so:
  
    ```app/app.js
    import Application from '@ember/application';
    import GlobalsResolver from '@ember/application/globals-resolver';
  
    App = Application.create({
      Resolver: GlobalsResolver.extend({
        resolveTemplate(parsedName) {
          let resolvedTemplate = this._super(parsedName);
          if (resolvedTemplate) { return resolvedTemplate; }
  
          return Ember.TEMPLATES['not_found'];
        }
      })
    });
    ```
  
    Some examples of how names are resolved:
  
    ```text
    'template:post'           //=> Ember.TEMPLATES['post']
    'template:posts/byline'   //=> Ember.TEMPLATES['posts/byline']
    'template:posts.byline'   //=> Ember.TEMPLATES['posts/byline']
    'template:blogPost'       //=> Ember.TEMPLATES['blog-post']
    'controller:post'         //=> App.PostController
    'controller:posts.index'  //=> App.PostsIndexController
    'controller:blog/post'    //=> Blog.PostController
    'controller:basic'        //=> Controller
    'route:post'              //=> App.PostRoute
    'route:posts.index'       //=> App.PostsIndexRoute
    'route:blog/post'         //=> Blog.PostRoute
    'route:basic'             //=> Route
    'foo:post'                //=> App.PostFoo
    'model:post'              //=> App.Post
    ```
  
    @class GlobalsResolver
    @extends EmberObject
    @public
  */

  exports.default = _emberRuntime.Object.extend({
    /**
      This will be set to the Application instance when it is
      created.
       @property namespace
      @public
    */
    namespace: null,

    init: function () {
      this._parseNameCache = (0, _emberUtils.dictionary)(null);
    },
    normalize: function (fullName) {
      var _fullName$split = fullName.split(':'),
          type = _fullName$split[0],
          name = _fullName$split[1];

      (true && !(fullName.split(':').length === 2) && (0, _emberDebug.assert)('Tried to normalize a container name without a colon (:) in it. ' + 'You probably tried to lookup a name that did not contain a type, ' + 'a colon, and a name. A proper lookup name would be `view:post`.', fullName.split(':').length === 2));


      if (type !== 'template') {
        var result = name.replace(/(\.|_|-)./g, function (m) {
          return m.charAt(1).toUpperCase();
        });

        return type + ':' + result;
      } else {
        return fullName;
      }
    },


    /**
      This method is called via the container's resolver method.
      It parses the provided `fullName` and then looks up and
      returns the appropriate template or class.
       @method resolve
      @param {String} fullName the lookup string
      @return {Object} the resolved factory
      @public
    */
    resolve: function (fullName) {
      var parsedName = this.parseName(fullName);
      var resolveMethodName = parsedName.resolveMethodName;
      var resolved = void 0;

      if (this[resolveMethodName]) {
        resolved = this[resolveMethodName](parsedName);
      }

      resolved = resolved || this.resolveOther(parsedName);

      if (true) {
        if (parsedName.root && parsedName.root.LOG_RESOLVER) {
          this._logLookup(resolved, parsedName);
        }
      }

      if (resolved) {
        (0, _validateType.default)(resolved, parsedName);
      }

      return resolved;
    },


    /**
      Convert the string name of the form 'type:name' to
      a Javascript object with the parsed aspects of the name
      broken out.
       @param {String} fullName the lookup string
      @method parseName
      @protected
    */

    parseName: function (fullName) {
      return this._parseNameCache[fullName] || (this._parseNameCache[fullName] = this._parseName(fullName));
    },
    _parseName: function (fullName) {
      var _fullName$split2 = fullName.split(':'),
          type = _fullName$split2[0],
          fullNameWithoutType = _fullName$split2[1];

      var name = fullNameWithoutType;
      var namespace = (0, _emberMetal.get)(this, 'namespace');
      var root = namespace;
      var lastSlashIndex = name.lastIndexOf('/');
      var dirname = lastSlashIndex !== -1 ? name.slice(0, lastSlashIndex) : null;

      if (type !== 'template' && lastSlashIndex !== -1) {
        var parts = name.split('/');
        name = parts[parts.length - 1];
        var namespaceName = _emberRuntime.String.capitalize(parts.slice(0, -1).join('.'));
        root = _emberRuntime.Namespace.byName(namespaceName);

        (true && !(root) && (0, _emberDebug.assert)('You are looking for a ' + name + ' ' + type + ' in the ' + namespaceName + ' namespace, but the namespace could not be found', root));
      }

      var resolveMethodName = fullNameWithoutType === 'main' ? 'Main' : _emberRuntime.String.classify(type);

      if (!(name && type)) {
        throw new TypeError('Invalid fullName: `' + fullName + '`, must be of the form `type:name` ');
      }

      return {
        fullName: fullName,
        type: type,
        fullNameWithoutType: fullNameWithoutType,
        dirname: dirname,
        name: name,
        root: root,
        resolveMethodName: 'resolve' + resolveMethodName
      };
    },


    /**
      Returns a human-readable description for a fullName. Used by the
      Application namespace in assertions to describe the
      precise name of the class that Ember is looking for, rather than
      container keys.
       @param {String} fullName the lookup string
      @method lookupDescription
      @protected
    */
    lookupDescription: function (fullName) {
      var parsedName = this.parseName(fullName);
      var description = void 0;

      if (parsedName.type === 'template') {
        return 'template at ' + parsedName.fullNameWithoutType.replace(/\./g, '/');
      }

      description = parsedName.root + '.' + _emberRuntime.String.classify(parsedName.name).replace(/\./g, '');

      if (parsedName.type !== 'model') {
        description += _emberRuntime.String.classify(parsedName.type);
      }

      return description;
    },
    makeToString: function (factory, fullName) {
      return factory.toString();
    },


    /**
      Given a parseName object (output from `parseName`), apply
      the conventions expected by `Ember.Router`
       @param {Object} parsedName a parseName object with the parsed
        fullName lookup string
      @method useRouterNaming
      @protected
    */
    useRouterNaming: function (parsedName) {
      if (parsedName.name === 'basic') {
        parsedName.name = '';
      } else {
        parsedName.name = parsedName.name.replace(/\./g, '_');
      }
    },

    /**
      Look up the template in Ember.TEMPLATES
       @param {Object} parsedName a parseName object with the parsed
        fullName lookup string
      @method resolveTemplate
      @protected
    */
    resolveTemplate: function (parsedName) {
      var templateName = parsedName.fullNameWithoutType.replace(/\./g, '/');

      return (0, _emberGlimmer.getTemplate)(templateName) || (0, _emberGlimmer.getTemplate)(_emberRuntime.String.decamelize(templateName));
    },


    /**
      Lookup the view using `resolveOther`
       @param {Object} parsedName a parseName object with the parsed
        fullName lookup string
      @method resolveView
      @protected
    */
    resolveView: function (parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    },


    /**
      Lookup the controller using `resolveOther`
       @param {Object} parsedName a parseName object with the parsed
        fullName lookup string
      @method resolveController
      @protected
    */
    resolveController: function (parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    },

    /**
      Lookup the route using `resolveOther`
       @param {Object} parsedName a parseName object with the parsed
        fullName lookup string
      @method resolveRoute
      @protected
    */
    resolveRoute: function (parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    },


    /**
      Lookup the model on the Application namespace
       @param {Object} parsedName a parseName object with the parsed
        fullName lookup string
      @method resolveModel
      @protected
    */
    resolveModel: function (parsedName) {
      var className = _emberRuntime.String.classify(parsedName.name);
      var factory = (0, _emberMetal.get)(parsedName.root, className);

      return factory;
    },

    /**
      Look up the specified object (from parsedName) on the appropriate
      namespace (usually on the Application)
       @param {Object} parsedName a parseName object with the parsed
        fullName lookup string
      @method resolveHelper
      @protected
    */
    resolveHelper: function (parsedName) {
      return this.resolveOther(parsedName);
    },

    /**
      Look up the specified object (from parsedName) on the appropriate
      namespace (usually on the Application)
       @param {Object} parsedName a parseName object with the parsed
        fullName lookup string
      @method resolveOther
      @protected
    */
    resolveOther: function (parsedName) {
      var className = _emberRuntime.String.classify(parsedName.name) + _emberRuntime.String.classify(parsedName.type);
      var factory = (0, _emberMetal.get)(parsedName.root, className);
      return factory;
    },
    resolveMain: function (parsedName) {
      var className = _emberRuntime.String.classify(parsedName.type);
      return (0, _emberMetal.get)(parsedName.root, className);
    },


    /**
      @method _logLookup
      @param {Boolean} found
      @param {Object} parsedName
      @private
    */
    _logLookup: function (found, parsedName) {
      var symbol = found ? '[✓]' : '[ ]';

      var padding = void 0;
      if (parsedName.fullName.length > 60) {
        padding = '.';
      } else {
        padding = new Array(60 - parsedName.fullName.length).join('.');
      }

      (0, _emberDebug.info)(symbol, parsedName.fullName, padding, this.lookupDescription(parsedName.fullName));
    },


    /**
      Used to iterate all items of a given type.
       @method knownForType
      @param {String} type the type to search for
      @private
    */
    knownForType: function (type) {
      var namespace = (0, _emberMetal.get)(this, 'namespace');
      var suffix = _emberRuntime.String.classify(type);
      var typeRegexp = new RegExp(suffix + '$');

      var known = (0, _emberUtils.dictionary)(null);
      var knownKeys = Object.keys(namespace);
      for (var index = 0; index < knownKeys.length; index++) {
        var name = knownKeys[index];

        if (typeRegexp.test(name)) {
          var containerName = this.translateToContainerFullname(type, name);

          known[containerName] = true;
        }
      }

      return known;
    },


    /**
      Converts provided name from the backing namespace into a container lookup name.
       Examples:
       * App.FooBarHelper -> helper:foo-bar
      * App.THelper -> helper:t
       @method translateToContainerFullname
      @param {String} type
      @param {String} name
      @private
    */
    translateToContainerFullname: function (type, name) {
      var suffix = _emberRuntime.String.classify(type);
      var namePrefix = name.slice(0, suffix.length * -1);
      var dasherizedName = _emberRuntime.String.dasherize(namePrefix);

      return type + ':' + dasherizedName;
    }
  });
});